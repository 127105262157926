'use client';

import { Input } from '@codegouvfr/react-dsfr/Input';
import { PasswordInput } from '@codegouvfr/react-dsfr/blocks/PasswordInput';
import { signIn } from 'next-auth/react';
import { useState } from 'react';
import { useRouter } from 'next/navigation';
import Alert from '@codegouvfr/react-dsfr/Alert';
type CreateAccountErrors = {
  email: string[];
  username: string[];
  lastName: string[];
  firstName: string[];
  password: string[];
  confirmPassword: string[];
};
const noErrors: () => CreateAccountErrors = () => ({
  email: [],
  username: [],
  lastName: [],
  firstName: [],
  password: [],
  confirmPassword: []
});
export default function CreateAccountForm() {
  const [createAccountErrors, setCreateAccountErrors] = useState(noErrors());
  const [genericError, setGenericError] = useState(false);
  const router = useRouter();
  const clearError = (field: keyof CreateAccountErrors) => {
    setCreateAccountErrors({
      ...createAccountErrors,
      [field]: []
    });
  };
  const prevalidateForm = (formElement: HTMLFormElement) => {
    const formData = new FormData(formElement);
    const email = formData.get('email');
    const username = formData.get('username');
    const password = formData.get('password');
    const confirmPassword = formData.get('confirmPassword');
    const lastName = formData.get('lastName');
    const firstName = formData.get('firstName');
    const errors: CreateAccountErrors = noErrors();
    const values = {
      email: email as string,
      username: username as string,
      password: password as string,
      confirmPassword: confirmPassword as string,
      lastName: lastName as string,
      firstName: firstName as string
    };
    if (password !== confirmPassword) {
      errors.confirmPassword = ['Les mots de passe ne correspondent pas'];
    }
    return {
      hasErrors: Object.values(errors).some(error => error.length > 0),
      errors,
      values
    };
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setGenericError(false);
    const {
      hasErrors,
      errors,
      values
    } = prevalidateForm(e.target);
    setCreateAccountErrors(errors);
    if (hasErrors) {
      return;
    }
    try {
      const response = await fetch(process.env.NEXT_PUBLIC_API_BASE + '/auth/user/create/', {
        method: 'POST',
        body: JSON.stringify({
          email: values.email,
          username: values.username,
          password: values.password,
          last_name: values.lastName,
          first_name: values.firstName
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (!response.ok) {
        const data = await response.json();
        setCreateAccountErrors({
          ...createAccountErrors,
          email: data.email || [],
          username: data.username || [],
          lastName: data.last_name || [],
          firstName: data.first_name || [],
          confirmPassword: data.password || []
        });
        return;
      }
      if (response.status !== 201) {
        throw new Error('Account creation did not return a 201 status code');
      }
      const loginResult = await signIn('credentials', {
        username: values.username,
        password: values.password,
        redirect: false
      });
      if (loginResult?.error) {
        throw new Error('Login following account creation did not work');
      }
      router.push('/votre-compte');
    } catch (error) {
      setGenericError(true);
      throw error;
    }
  };
  return <form onSubmit={handleSubmit} data-sentry-component="CreateAccountForm" data-sentry-source-file="CreateAccountForm.tsx">
      {genericError && <div className="fr-mb-3w">
          <Alert description="Une erreur est survenue lors de la création de votre compte" severity="error" closable={false} small={true} />
        </div>}
      <Input label="Email" nativeInputProps={{
      name: 'email',
      type: 'email',
      required: true,
      onChange: e => clearError('email')
    }} hintText="Ne sera pas visible publiquement sur le site" state={createAccountErrors.email.length > 0 ? 'error' : 'default'} stateRelatedMessage={createAccountErrors.email} data-sentry-element="Input" data-sentry-source-file="CreateAccountForm.tsx" />
      <Input label="Nom d'utilisateur" nativeInputProps={{
      name: 'username',
      required: true,
      onChange: e => clearError('username')
    }} state={createAccountErrors.username.length > 0 ? 'error' : 'default'} stateRelatedMessage={createAccountErrors.username} data-sentry-element="Input" data-sentry-source-file="CreateAccountForm.tsx" />
      <Input label="Nom" nativeInputProps={{
      name: 'lastName',
      onChange: e => clearError('lastName')
    }} state={createAccountErrors.lastName.length > 0 ? 'error' : 'default'} stateRelatedMessage={createAccountErrors.lastName} data-sentry-element="Input" data-sentry-source-file="CreateAccountForm.tsx" />
      <Input label="Prénom" nativeInputProps={{
      name: 'firstName',
      onChange: e => clearError('firstName')
    }} state={createAccountErrors.firstName.length > 0 ? 'error' : 'default'} stateRelatedMessage={createAccountErrors.firstName} data-sentry-element="Input" data-sentry-source-file="CreateAccountForm.tsx" />
      <PasswordInput label="Mot de passe" nativeInputProps={{
      name: 'password',
      required: true,
      onChange: e => clearError('password')
    }} data-sentry-element="PasswordInput" data-sentry-source-file="CreateAccountForm.tsx" />
      <PasswordInput label="Confirmation du mot de passe" messagesHint="" messages={createAccountErrors.confirmPassword.map(errorMessage => ({
      severity: 'error',
      message: errorMessage
    }))} nativeInputProps={{
      name: 'confirmPassword',
      required: true,
      onChange: e => clearError('confirmPassword')
    }} data-sentry-element="PasswordInput" data-sentry-source-file="CreateAccountForm.tsx" />
      <button className="fr-btn fr-mt-2w" type="submit">
        Créer un compte
      </button>
    </form>;
}